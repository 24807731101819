import React,{useState} from 'react'
import Play_btn from '../../assets/images/play_supply.svg'
import { logGoogleEvent } from '../../firebase';

const Section1v1 = ({icon, herotext, yt}) => {
    const [isActive, setIsActive] = useState(false);
    const [seed, setSeed] = useState(1);
    const reset = () => {
        setSeed(Math.random());
    }
    const handleClick = event => {
        logGoogleEvent("Product video played", {
            name: "Product video played",
          });
        setIsActive(current => !current);
        reset()
    }
  return (
    <div className='s1-container'>
        <div className='row f-width m-auto'>
            <div className='col-lg-6 col-sm-12'>
                <div className='s1v1-content'>
                    <div>
                        <img src={icon} alt=' Voosh Dispute Manager' />
                        <span className='text-white ps-2 fw-bold'>{herotext.t1}</span>
                    </div>
                    <h1 className='s1v1-header'>{herotext.t2}</h1>
                    <p className='s1v1-sub-header'>{herotext.t3}</p>
                    <a href='https://meetings.hubspot.com/brandon1142?uuid=cdea5658-3569-49e8-a8c9-21815bea54f8' onClick={() => {
                    logGoogleEvent("Product page [book demo button clicked]", {
                      name: "Product page [book demo button clicked]",
                    });
                  }}>
                    <div className='btn btn-primary btn-with-arrow text-white'><span className='pe-2'>Book Demo </span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                            <path d="M21.5 12H3.5H21.5Z" fill="white"/>
                            <path d="M14.5 5L21.5 12M21.5 12L14.5 19M21.5 12H3.5" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                    </div>
                    </a>
                </div>
            </div>
            <div className='col-lg-6 col-sm-12'>
                <div className='s1v1-youtube'>
                    <div className='ytubee' key={seed}>
                        <div className={isActive ? 'd-none' : 'yt_section'}>
                            <img className='yt-thumb' src={yt.thumbnail} alt='youtube_thumbnail' />
                            <div className='d-flex thumb-text-btn align-items-end' onClick={handleClick} data-toggle="tooltip" title="Play">
                                <p className="left-bottom mb-1">{yt.vtitle}</p>
                                <img src={Play_btn} className='play_btn' alt='play_button' />
                            </div>
                        </div>
                        <iframe className={isActive ? 'video_frame' : 'd-none'} src={isActive ? "https://www.youtube.com/embed/"+yt.vid+"?autoplay=1": "https://www.youtube.com/embed/"+yt.vid} title="Voosh: All In 1 Restaurant Dashboard for Delivery Financials, Reputation Management, and Operations!" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
  )
}

export default Section1v1