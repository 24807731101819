import React from 'react'
import { logGoogleEvent } from '../../firebase'

const Section1v2 = ({images,herotext}) => {
  return (
    <div className='s1-container'>
        <div className='row f-width m-auto'>
            <div className='col-lg-6 col-sm-12'>
                <div className='s1v1-content'>
                    <div>
                        <img style={{paddingBottom:"5px"}} src={images.img1} alt=' Voosh Dispute Manager' />
                        <span className='text-white ps-2 fw-bold'>{herotext.t1}</span>
                    </div>
                    <h1 className='s1v1-header'>{herotext.t2}</h1>
                    <p className='s1v1-sub-header pe-4'>{herotext.t3}</p>
                    <a href='https://meetings.hubspot.com/brandon1142?uuid=cdea5658-3569-49e8-a8c9-21815bea54f8' onClick={() => {
                    logGoogleEvent("Product page [book demo button clicked]", {
                      name: "Product page [book demo button clicked]",
                    });
                  }}>
                    <div className='btn btn-primary btn-with-arrow text-white'><span className='pe-2'>Book Demo </span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                            <path d="M21.5 12H3.5H21.5Z" fill="white"/>
                            <path d="M14.5 5L21.5 12M21.5 12L14.5 19M21.5 12H3.5" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                    </div>
                    </a>
                </div>
            </div>
            <div className='col-lg-6 col-sm-12'>
                <div className='AI-hero-img'>
                    <img className='analytics-insights-img pm-image' src={images.img2} alt='Voosh Analytics & Insights' />
                </div>
                
            </div>
        </div>
    </div>
  )
}

export default Section1v2